.process {
    padding: 40px 20px;
    text-align: center;
    background: #F0F3F9;
}

.process-link {
    font-family: Spartan;
    font-size: 22px;
    font-weight: 700;
    line-height: 40px;
    color: #151D79;
    text-decoration: underline;
}

.process h2 {
    font-family: Spartan;
    font-size: 38px;
    font-weight: 700;
    line-height: 50px;
    color: #333;
    margin-bottom: 40px;
    margin-top: 0;
}

.process-content {
    max-width: 1140px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
}

.process-card {
    background-color: white;
    max-width: 335px;
    max-height: 276px;
    min-width: 335px;
    min-height: 276px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    position: relative;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 0 0 30px 0;
}

.process-icon {
    position: absolute;
    width: 110px;
    height: 110px;
    top: -30px;
    left: -30px;
}

.process-card-text h3 {
    font-family: Spartan;
    font-size: 22px;
    font-weight: 700;
    line-height: 32px;
    color: #333;
    margin-top: 120px;
    margin-left: 20px;
    margin-bottom: 0;
}

.process-card-text p {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    color: #4A4E56;
    margin-left: 20px;
    margin-top: 10px;
}

.read-more {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    color: #151D79;
    text-decoration: none;
    margin-left: 20px;
}

.process-number {
    position: absolute;
    top: 20px;
    right: 20px;
    font-family: Spartan;
    font-size: 24px;
    font-weight: 700;
    line-height: 60px;
    text-align: left;
    color: #151D79;
    opacity: 0.5;
}

.buttons21{
    display: flex;
    justify-content: center;
    margin: 20px 0;
}

.solicitar-button21, .renovar-button21 {
    padding: 15px 30px;
    border: none;
    cursor: pointer;
    margin-right: 10px;
    border-radius: 5px;
    font-family: 'Spartan', sans-serif;
    font-size: 17px;
    font-weight: 600;
    border-radius: 10px 0 10px 0;
    margin-left: 50px;
}

.solicitar-button21 {
    background-color: #151D79;
    color: white;
}

.renovar-button21 {
    background-color: black;
    color: white;
}

@media (max-width: 800px) {
    .process-content {
        justify-content: center;
    }
}

@media (max-width: 450px) {
    .process-card {
        max-width: 100%;
        min-width: auto;
        padding: 10px;
    }
}
