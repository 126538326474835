/* LegalAdvertise.css */
.legal-advertise {
    background-color: #151D79;
    color: white;
    text-align: center;
    padding: 20px;
}

.legal-advertise-content {
    max-width: 1140px;
    margin: 0 auto;
    font-size: 12px;
}
.legal-advertise-content p{
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
}