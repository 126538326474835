/* AutorizacionUrgente.css */
.autorizacion-urgente {
    background-image: 
        linear-gradient(to left, rgba(255, 255, 255, 0.22), #f0f3f9),
        url('../../public/background-image_emergency.png');
        background-size: cover;
        background-position: center;
        padding: 80px 20px;
        color: #333;
    }
    
    .autorizacion-content-urgente {
        max-width: 1140px;
        margin: 0 auto;
        text-align: left;
    }
    
    .autorizacion-content-urgente h2 {
        font-family: 'Spartan', sans-serif;
        font-size: 22px;
        font-weight: 700;
        line-height: 40px;
        color: #151D79;
    }
    
    .autorizacion-content-urgente h1 {
        font-family: 'Spartan', sans-serif;
        font-size: 23px;
        font-weight: 800;
        line-height: 32px;
        color: #333;
        margin-bottom: 20px;
    }
    
    .requisitos-urgente {
        list-style: none;
        padding: 0;
        margin-bottom: 20px;
        font-family: 'Roboto', sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: #4A4E56;
    }
    
    .requisitos-urgente li {
        margin-bottom: 10px;
        margin-left: 20px;
    }
    
    .nota-urgente {
        font-family: 'Inter', sans-serif;
        font-size: 12px;
        font-weight: 400;
        line-height: 26px;
        color: #4A4E56;
        margin-bottom: 20px;
    }
    
    .botones-urgente {
        display: flex;
        gap: 20px;
        margin-bottom: 20px;
    }
    
    .solicitar-emergencia-urgente, .visa-regular-urgente {
        padding: 15px 20px;
        border: none;
        cursor: pointer;
        border-radius: 10px;
        font-family: 'Spartan', sans-serif;
        font-size: 17px;
        border-radius: 10px 0  10px 0;
        margin-left: 25px;
    }
    
    .solicitar-emergencia-urgente {
        background-color: #151D79;
        color: white;
    }
    
    .visa-regular-urgente {
        background-color: black;
        color: white;
    }
    
    .nota-final-urgente {
        font-family: 'Spartan', sans-serif;
        font-size: 11px;
        font-weight: 700;
        line-height: 16.5px;
        color: #4A4E56;
    }
    .autorizacion_content{
        max-width: 700px;
    }