/* AboutAutorizacion.css */
.about-autorizacion {
    background-color: #F0F3F9;
    padding: 40px 20px;
    color: #333;
}

.about-content {
    max-width: 1140px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.about-images {
    display: flex;
    flex-direction: column;
    position: relative;
    gap: 20px;
}

.main-image {
    max-width: 500px;
    width: 100%;
    height: auto;
    border-radius: 8px;
}

.secondary-image {
    max-width: 200px;
    width: 100%;
    height: auto;
    position: absolute;
    bottom: -30px;
    right: -30px;
    border-radius: 8px;
}

.about-text {
    flex: 1;
    padding-left: 40px;
}

.about-text h3 {
    color: #151D79;
    font-family: Spartan;
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    text-align: left;
    margin-bottom: 0;
}

.about-text h1 {
    color: #333;
    font-family: Spartan;
    font-size: 38px;
    font-weight: 700;
    line-height: 50px;
    text-align: left;
    margin: 0px 0;
}

.about-text p {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    text-align: left;
    margin-bottom: 20px;
    max-width: 530px;
}

.buttons {
    display: flex;
    width: 100%;
    
    justify-content:space-around;
    gap: 20px;
}

.solicitar-button2, .renovar-button2 {
    padding: 15px 20px;
    border: none;
    cursor: pointer;
    width: 160px;
    border-radius: 30px;
    font-family: Spartan;
    font-size: 17px;
    font-weight: 600;
}

.solicitar-button2 {
    background-color: #151D79;
    color: white;
}

.renovar-button2 {
    background-color: black;
    color: white;
}

/* Media Query para pantallas menores a 800px */
@media (max-width: 950px) {
    .about-content {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .about-text {
        padding-left: 0;
        order: 1;
    }
    .about-text p {
        text-align: center;
    }
    .about-text h3 {
        text-align: center;
    }
    .about-text h1 {
        text-align: center;
    }
    .buttons {
        justify-content: center;
        margin-top: 20px;
        order: 2;
    }

    .solicitar-button2, .renovar-button2 {
        margin: 10px 0;
    }

    .about-images {
        order: 3;
        margin-top: 20px;
    }

    .main-image {
        max-width: 300px;
    }

    .secondary-image {
        max-width: 100px;
        bottom: -15px;
        right: -15px;
    }
}
