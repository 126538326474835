/* SistemaOptimizado.css */
.sistema-optimizado {
    background-color: #FFFFFF;
    padding: 40px 20px;
    text-align: center;
}

.sistema-optimizado h2 {
    font-family: 'Spartan', sans-serif;
    font-size: 22px;
    font-weight: 700;
    line-height: 40px;
    text-align: center;
    color: #151D79;
    margin-bottom: 40px;
    text-decoration: underline;
}

.cards {
    max-width: 1140px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.card {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    max-width: 500px;
    flex: 1 1 100%;
    margin: 10px;
    display: flex;
    align-items: center;
}

.card-icon {
    width: 65px;
    height: 60px;
    margin-right: 20px;
}

.card p {
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    text-align: left;
    color: #4A4E56;
    margin: 0;
}
