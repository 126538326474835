.inicio {
    background-image: 
        linear-gradient(to left, rgba(255, 255, 255, 0.22), #f0f3f9),
        url('../../public/background-image.png');
    background-size: cover;
    background-position: center;
    padding: 40px 20px;
    color: #333;
}

.inicio-content {
    max-width: 1140px;
    margin: 50px auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.inicio-left {
    flex: 1;
    color: white;
}

.inicio-left h1 {
    margin: 0;
    color: #151D79;
    font-family: Spartan;
    font-size: 22px;
    font-weight: 700;
    line-height: 40px;
    text-align: left;
}

.inicio-left h2 {
    color: black;
    font-family: Spartan;
    font-size: 51px;
    font-weight: 800;
    line-height: 66px;
    text-align: left;
    margin: 0;
    max-width: 500px;
}

.inicio-left ul {
    list-style: none;
    font-family: Inter;
    font-size: 17px;
    font-weight: 400;
    line-height: 27px;
    text-align: left;
    color: black;
    padding: 0;
}

.inicio-left ul li {
    margin: 5px 0;
}

.inicio-right {
    flex: 1;
    background-color: white;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    max-width: 259px;
    height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.inicio-right-content {
    display: flex;
    flex-direction: column;
}

.inicio-right-content a {
    color: #151D79;
    font-family: Spartan;
    font-size: 20px;
    font-weight: 600;
    line-height: 25px;
    text-align: left;
    margin: 0 auto;
}

.inicio-right-content h3 {
    margin-bottom: 5px;
    color: #333;
    font-family: Spartan;
    font-size: 22px;
    font-weight: 600;
    line-height: 32px;
    text-align: left;
}

.solicitar-button1, .renovar-button1 {
    padding: 15px 20px;
    border: none;
    cursor: pointer;
    margin: 10px auto;
    width: 160px;
    border-radius: 10px 0px 10px 0px;
}

.solicitar-button1 {
    background-color: #151D79;
    color: white;
}

.renovar-button1 {
    background-color: black;
    color: white;
}

/* Media query para pantallas menores a 800px */
@media (max-width: 800px) {
    .inicio-content {
        flex-direction: column;
        align-items: center;
    }

    .inicio-left, .inicio-right {
        max-width: 100%;
        flex: none;
        margin-bottom: 20px;
    }

    .inicio-right {
        max-width: 100%;
        height: auto;
    }
}
