/* V_autorizacion.css */
.autorizacion {
    background-image: 
        linear-gradient(to left, rgba(255, 255, 255, 0.22), #f0f3f9),
        url('../../public/background_menu2.png');
    background-size: cover;
    background-position: center;
    padding: 40px 20px;
    color: #333;
}

.autorizacion-content {
    max-width: 1140px;
    margin: 50px auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: left;
}

.autorizacion-left {
    flex: 1;
    color: white;
}

.autorizacion-left h1 {
    margin: 0;
    color: #151D79;
    font-family: Spartan;
    font-size: 22px;
    font-weight: 700;
    line-height: 40px;
    text-align: left;
}

.autorizacion-left h2 {
    color: black;
    font-family: Spartan;
    font-size: 51px;
    font-weight: 800;
    line-height: 66px;
    text-align: left;
    margin: 0;
    max-width: 500px;
}

.autorizacion-left ul {
    list-style: none;
    font-family: Inter;
    font-size: 17px;
    font-weight: 400;
    line-height: 27px;
    text-align: left;
    color: black;
    padding: 0;
}

.autorizacion-left ul li {
    margin: 5px 0;
}

.buttons20 {
    display: flex;
    gap: 20px;
    max-width: 300px;
}

.solicitar-button, .renovar-button {
    padding: 15px 20px;
    border: none;
    cursor: pointer;
    width: 160px;
    border-radius: 10px;
    font-family: Spartan;
    font-size: 17px;
    font-weight: 600;
    border-radius: 10px 0px 10px 0px;
}

.solicitar-button {
    background-color: #151D79;
    color: white;
}

.renovar-button {
    background-color: black;
    color: white;
}
