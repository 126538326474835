.visa-info {
    padding: 40px 20px;
}

.visa-info-content {
    max-width: 1140px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.visa-info-image-container {
    position: relative;
    max-width: 550px;
    flex: 1 1 550px;
}

.image-label {
    position: absolute;
    top: 20px;
    left: -20px;
    background-color: #151D79;
    color: white;
    padding: 5px 10px;
    font-family: Spartan;
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    text-align: left;
}

.visa-info-image {
    width: 100%;
    max-width: 550px;
    height: auto;
    border-radius: 8px;
}

.visa-info-text {
    flex: 1 1 400px;
    padding: 20px;
    text-align: left;
}

.visa-info-text h2 {
    font-family: Spartan;
    font-size: 38px;
    font-weight: 700;
    line-height: 50px;
    color: black;
    margin-bottom: 20px;
}

.visa-info-text .description {
    font-family: Inter;
    font-size: 17px;
    font-weight: 400;
    line-height: 27px;
    color: #333;
    margin-bottom: 20px;
}

.visa-info-text .additional-info {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    color: #7A7A7A;
    margin-bottom: 30px;
}

.button-row {
    display: flex;
    gap: 20px;
    justify-content: flex-start;
}

.solicitar-button, .renovar-button {
    padding: 15px 20px;
    border: none;
    cursor: pointer;
    width: 160px;
    border-radius: 10px 0px 10px 0px;
}

.solicitar-button {
    background-color: #151D79;
    color: white;
}

.renovar-button {
    background-color: black;
    color: white;
}

@media (max-width: 1100px) {
    .visa-info-image-container {
        max-width: 40%;
    }
}

@media (max-width: 800px) {
    .visa-info-content {
        flex-direction: column;
        align-items: center;
    }

    .visa-info-image-container {
        max-width: 100%;
        flex: none;
        margin-bottom: 20px;
    }

    .visa-info-text {
        text-align: center;
        padding: 0;
    }

    .visa-info-image {
        width: 100%;
        max-width: 100%;
    }

    .image-label {
        top: 10px;
        left: 10px;
        font-size: 16px;
        padding: 3px 8px;
    }

    .button-row {
        flex-direction: column;
        align-items: center;
    }
}
