.formulario-detalle-ds160 {
    background-color: #F0F3F9;
    padding: 40px 20px;
    color: #333;
    text-align: center;
}

.formulario-detalle-content {
    max-width: 1140px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.formulario-detalle-image {
    flex: 1;
    position: relative;
}

.main-image {
    width: 100%;
    height: auto;
    border-radius: 8px;
}

.small-image {
    width: 250px;
    height: auto;
    border-radius: 8px;
    position: absolute;
    bottom: -30px;
    right: -30px;
}

.formulario-detalle-text {
    flex: 1;
    padding-left: 40px;
    text-align: left;
}

.conoce-mas {
    display: block;
    font-family: 'Spartan', sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
    color: #151D79;
    margin-bottom: 10px;
}

.formulario-detalle-text h1 {
    font-family: 'Spartan', sans-serif;
    font-size: 38px;
    font-weight: 700;
    line-height: 50px;
    color: #333;
    margin-bottom: 20px;
}

.formulario-detalle-text p {
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    color: #4A4E56;
    margin-bottom: 20px;
}

.personalizado {
    display: block;
    font-family: 'Spartan', sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
    color: #151D79;
    margin-bottom: 20px;
    text-align: right;
    text-decoration: none;
}

.formulario-detalle-text h2 {
    font-family: 'Spartan', sans-serif;
    font-size: 22px;
    font-weight: 700;
    line-height: 40px;
    color: #333;
    text-align: right;
    margin-bottom: 20px;
}

.nueva-solicitud-button {
    padding: 15px 20px;
    border: none;
    cursor: pointer;
    border-radius: 30px;
    font-family: 'Spartan', sans-serif;
    font-size: 17px;
    background-color: #151D79;
    color: white;
    display: block;
    margin-top: 20px;
    text-align: center;
    margin: 0 auto;
}

/* Media Query para pantallas menores a 800px */
@media (max-width: 800px) {
    .formulario-detalle-content {
        flex-direction: column;
        text-align: center;
    }

    .formulario-detalle-image {
        order: 2;
        margin-top: 20px;
    }

    .formulario-detalle-text {
        order: 1;
        padding-left: 0;
    }

    .formulario-detalle-text h1,
    .formulario-detalle-text p,
    .conoce-mas,
    .personalizado,
    .formulario-detalle-text h2 {
        text-align: center;
    }

    .small-image {
        width: 150px;
        bottom: -10px;
        right: 10px;
    }
}
