.contact-container {
  max-width: 1140px;
  margin: 0 auto;
  padding: 40px 20px;
  display: flex;
  justify-content: center;

  flex-wrap: wrap;
}

.contact-form {
  flex: 1;
  background: #f2f2f2;
  padding: 0px;
  border-radius: 8px;
  border: 1px solid #f4f4f4;
}

.contact-form form {
  display: flex;
  flex-direction: column;
}

.contact-form input,
.contact-form select {
  margin-bottom: 35px;
  padding: 20px;
  font-size: 16px;
  border: 1px solid #d1d5db;
  border-radius: 4px;
  background-color: #e6e6e6;
}

.contact-form button {
  padding: 10px;
  background-color: #00bfff;
  color: white;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 30px;
}

.contact-info {
  flex: 1;
  padding: 20px;
  max-width: 500px;
}

.contact-info h2 {
  font-family: "Spartan", sans-serif;
  font-size: 22px;
  font-weight: 700;
  line-height: 26px;
  color: #151d79;
  text-align: left;
  text-decoration: underline;
}

.contact-info h3 {
  font-family: "Spartan", sans-serif;
  font-size: 33px;
  font-weight: 700;
  line-height: 42px;
  color: #282f3b;
  text-align: left;
  margin: 10px 0;
}

.contact-info p {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  color: #4a4e56;
  text-align: left;
  margin: 0;
}

.contact-details {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
}

.contact-detail {
  display: flex;
  align-items: center;
  gap: 15px;
  border: 1px solid #e4e7ee;
  border-radius: 15px;
  padding: 10px;
}

.contact-detail img {
  width: 40px;
  height: 40px;
}

.contact-detail strong {
  font-family: "Spartan", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 26px;
  color: #282f3b;
  text-align: left;
}

.contact-detail p {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  color: #4a4e56;
  text-align: left;
}

/* Media Query para pantallas menores a 600px */
@media (max-width: 700px) {
  .contact-container {
    flex-direction: column;
    align-items: center;
  }

  .contact-info {
    max-width: 100%;
  }

  .contact-form {
    max-width: 100%;
    width: 100%;
  }

  .contact-form input,
  .contact-form select {
    padding: 15px;
  }

  .contact-form button {
    width: 100%;
  }
}
