/* FormularioDS160.css */
.formulario-ds160 {
    background-image: 
        linear-gradient(to left, rgba(255, 255, 255, 0.22), #f0f3f9),
        url('../../public/background_menu3.png');
    background-size: cover;
    background-position: center;
    padding: 40px 20px;
    color: #333;
}

.formulario-content {
    max-width: 1140px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    color: white;
}
.formulario_max{
    max-width: 1140px;
    margin: 50px auto;
}

.formulario-content h2 {
    font-family: 'Spartan', sans-serif;
    font-size: 22px;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: 1.3px;
    color: #151D79;
    margin-bottom: 0px;
}

.formulario-content h1 {
    font-family: 'Spartan', sans-serif;
    font-size: 33px;
    font-weight: 800;
    line-height: 42px;
    color: #282F3B;
    margin-bottom: 20px;
    max-width: 500px;
    margin-top: 0;
}

.formulario-content ul {
    list-style: none;
    padding: 0;
    margin-bottom: 30px;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    color: #4A4E56;
}

.formulario-content ul li {
    margin-bottom: 10px;
}

.buttons45 {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.solicitar-button5, .renovar-button5 {
    padding: 15px 40px;
    border: none;
    cursor: pointer;
    border-radius: 10px 0 10px 0;
    font-family: 'Spartan', sans-serif;
    font-size: 17px;
    font-weight: 600;
    margin-left: 30px;
}

.solicitar-button5 {
    background-color: #151D79;
    color: white;
}

.renovar-button5 {
    background-color: black;
    color: white;
}
@media (max-width: 800px) {
    .solicitar-button5, .renovar-button5 {
        margin-left: 0;
    }
}