/* GuiaEntrevista.css */
.guia-entrevista {
    padding: 40px 20px;
    text-align: center;
    background-color: #FFFFFF;
}

.guia-entrevista-content {
    max-width: 1140px;
    margin: 0 auto;
}

.guia-entrevista h2 {
    font-family: 'Spartan', sans-serif;
    font-size: 22px;
    font-weight: 700;
    line-height: 40px;
    color: #151D79;
    margin-bottom: 20px;
}

.guia-entrevista p {
    font-family: 'Inter', sans-serif;
    font-size: 17px;
    font-weight: 400;
    line-height: 27px;
    color: #4A4E56;
}
