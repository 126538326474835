.comparison {
    text-align: center;
    padding: 40px 0;
    background-color: #f9f9f9;
}

.comparison h2 {
    font-family: Spartan;
    font-size: 38px;
    font-weight: 700;
    line-height: 50px;
    color: #282F3B;
    margin-bottom: 40px;
}

.comparison-content {
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
}

.comparison-card {
    background-color: white;
    width: 550px;
    height: 610px;
    box-sizing: border-box;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    padding: 20px;
}

.recommended {
    position: absolute;
    top: 100px;
    right: -70px;
    background-color: #151D79;
    color: white;
    padding: 5px 10px;
    font-family: Inter;
    font-size: 13px;
    font-weight: 400;
    line-height: 19.5px;
    text-align: left;    
    transform: rotate(-90deg);
    transform-origin: left top;
}

.comparison-card h3 {
    font-family: Spartan;
    font-size: 30px;
    font-weight: 700;
    line-height: 40px;
    text-align: left;    
    color: #282F3B;
    margin-bottom: 0px;
}

.comparison-card p {
    font-family: Spartan;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    color: #333;
    margin-top: 0;
    margin-bottom: 0;
}
.Cub{
    width: 60px;
    height: 20px;
    background-color: #151D79;
    margin-top: 0;
}
.comparison-card ul {
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
}

.comparison-card ul li {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;    
    color: #333;
    display: flex;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
}

.comparison-card ul li:nth-child(odd) {
    background-color: #F0F3F9;
}

.checkmark {
    color: #151D79;
    margin-right: 10px;
}

.crossmark {
    color: #151D79;
    margin-right: 10px;
}
