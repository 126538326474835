.conoce-mas2 {
    background-color: #F5F6F9;
    padding: 40px 20px;
}

.content {
    max-width: 1140px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.content3{
    flex-direction: row;
}
.left {
    position: relative;
    max-width: 550px;
}

.main-image {
    width: 100%;
    border-radius: 8px;
}

.small-image {
    position: absolute;
    bottom: -20px;
    right: 20px;
    width: 150px;
    border-radius: 8px;
}

.right {
    max-width: 500px;
    padding-left: 20px;
}

.highlight-text {
    font-family: 'Spartan', sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
    text-align: left;
    color: #151D79;
    display: block;
    margin-bottom: 10px;
}

.right h1 {
    font-family: 'Spartan', sans-serif;
    font-size: 31px;
    font-weight: 700;
    line-height: 50px;
    text-align: left;
    color: #333;
    margin-bottom: 20px;
}

.right p {
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    text-align: left;
    color: #4A4E56;
    margin-bottom: 20px;
}

.buttons2 {
    display: flex;
    justify-content: flex-start;
    gap: 20px;
    margin-top: 20px;
}

.solicitar-button10, .renovar-button10 {
    padding: 15px 30px;
    border: none;
    cursor: pointer;
    margin-right: 10px;
    border-radius: 30px;
    font-family: 'Spartan', sans-serif;
    font-size: 17px;
    font-weight: 600;
}

.solicitar-button10 {
    background-color: #151D79;
    color: white;
}

.renovar-button10 {
    background-color: black;
    color: white;
}

/* Media Query para pantallas menores a 800px */
@media (max-width: 800px) {
    .content {
        flex-direction: column;
        text-align: center;
    }

    .left {
        order: 2;
        max-width: 100%;
        margin-top: 20px;
    }

    .main-image {
        width: 100%;
    }

    .small-image {
        width: 100px;
        height: auto;
        bottom: -10px;
        right: 10px;
    }

    .right {
        order: 1;
        padding-left: 0;
    }

    .right h1,
    .right p,
    .highlight-text {
        text-align: center;
    }

    .buttons2 {
        justify-content: center;
        margin-top: 20px;
    }

    .solicitar-button10, .renovar-button10 {
        margin: 10px 0;
    }
}
