.emergencia-autorizacion {
    background-color: #F5F6F9;
    padding: 40px 20px;
    color: #333;
}

.emergencia-content {
    max-width: 1140px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.emergencia-left {
    position: relative;
    max-width: 50%;
}

.image-left {
    width: 100%;
    max-width: 550px;
    border-radius: 8px;
}

.emergencia-label {
    position: absolute;
    top: 20px;
    left: -30px;
    background-color: #151D79;
    color: white;
    padding: 10px 20px;
    font-family: 'Spartan', sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
}

.emergencia-right {
    max-width: 500px;
    margin-left: 10px;
}

.urgente-link {
    font-family: 'Spartan', sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
    color: #151D79;
    text-decoration: none;
    margin-bottom: 10px;
    display: block;
}

.emergencia-right h1 {
    font-family: 'Spartan', sans-serif;
    font-size: 38px;
    font-weight: 700;
    line-height: 50px;
    color: #333;
    margin-bottom: 20px;
}

.emergencia-right p {
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    color: #4A4E56;
    margin-bottom: 20px;
}

.botones-emergencia {
    display: flex;
    justify-content: space-around;
    gap: 20px;
}

.visa-emergencia, .visa-regular {
    padding: 15px 20px;
    border: none;
    cursor: pointer;
    border-radius: 30px;
    font-family: 'Spartan', sans-serif;
    font-size: 17px;
    background-color: #151D79;
    color: white;
}

.visa-regular {
    background-color: black;
    color: white;
}

/* Media Query para pantallas menores a 850px */
@media (max-width: 850px) {
    .emergencia-content {
        flex-direction: column;
        text-align: center;
    }

    .emergencia-left {
        order: 2;
        max-width: 100%;
        margin-top: 20px;
    }

    .emergencia-right {
        order: 1;
        max-width: 100%;
    }

    .image-left {
        margin-top: 20px;
        max-width: 70%;
    }

    .emergencia-label {
        position: absolute;
        top: 50px;
        left: -0;
    }
    .emergencia-label {
        font-size: 10px;
    }
}
