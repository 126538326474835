/* VisaForm.css */
.visa-form-container {
    max-width: 1140px;
    margin: 0 auto;
    padding: 40px 20px;
}

.visa-form-container h1 {
    font-family: Arial, sans-serif;
    font-size: 24px;
    font-weight: 400;
    line-height: 33px;
    letter-spacing: 0.6px;
    text-align: center;
}

.form-subtitle {
    font-family: Arial, sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 17px;
    text-align: center;
}

.form-section {
    background: #FFFFFF;
    padding: 20px;
    border-radius: 8px;
    text-align: left;
    max-width: 600px;
    margin: 0 auto;
    
}

.section-header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    
}

.section-step {
    font-family: Arial, sans-serif;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    margin-right: 10px;
    color: #00BFFF;
    
}

.section-header h2 {
    font-family: Arial, sans-serif;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    
}

.form-group {
    margin-bottom: 15px;

}
.raya{
    max-width: 600px;
    width: 100%;
    height: 2px;
    background-color:#F6F6F7;
}

.form-group select, 
.form-group input {
    max-width: 600px;
    width: 100%;
    padding: 0px 0;
    height: 52px;
    font-size: 16px;
    border: 1px solid #D1D5DB;
    border-radius: 4px;
    margin-bottom: 5px;
    background-color: #F2F2F2;
}
.form-group select {
    padding-left: 10px; 
}
.form-group input::placeholder {
    padding-left: 15px;
    color: #999;
    background-color: #F2F2F2;
}
.Raya2{
    max-width: 600px;
    width: 100%;
    height: 5px;
    margin-top: 20px;
    background-color:#00AEEF;
}
.Checbox{
    padding: 10px;
}
.Checbox input{
    background-color: #F2F2F2;
}
.form-section p {
    font-family: Arial, sans-serif;
    font-size: 13px;
    font-weight: 400;
    line-height: 14.92px;
    text-align: left;
    margin-top: -10px;
    margin-bottom: 15px;
    color: #4A4E56;
}

.checkbox-group {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 10px;
}

.checkbox-group input[type="checkbox"] {
    margin-right: 10px;
}

.checkbox-group label {
    font-family: Arial, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 18.36px;
    text-align: left;
}

.submit-button {
    padding: 10px;
    background-color: #00BFFF;
    color: white;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    display: block;
    width: 100%;
    margin-top: 20px;
}
