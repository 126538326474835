.package {
    text-align: center;
    padding: 40px 20px;
}

.package a {
    color: #151D79;
    font-family: Spartan;
    font-size: 22px;
    font-weight: 700;
    line-height: 40px;
    text-align: center;
    margin: 0;
    text-decoration: underline;
}

.package h3 {
    font-family: Spartan;
    font-size: 38px;
    font-weight: 700;
    line-height: 50px;
    text-align: center;
    margin-bottom: 60px;
    margin-top: 0;
}

.package-content {
    max-width: 1140px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.package-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
}

.package-card-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
}

.package-card {
    background-color: white;
    flex: 1 1 calc(33.333% - 20px);
    margin: 10px;
    height: 131px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    cursor: pointer;
}

.icon1, .icon2, .icon3, .icon4, .icon5, .icon6 {
    margin: 0 20px;
}

.icon1 {
    width: 60px;
    height: 60px;
}
.icon2 {
    width: 49px;
    height: 60px;
    margin-right: 40px;
}
.icon3 {
    width: 60px;
    height: 50px;
    margin-right: 30px;
}
.icon4 {
    width: 60px;
    height: 60px;
}
.icon5 {
    width: 50px;
    height: 50px;
}
.icon6 {
    width: 75px;
    height: 50px;
}

.package-card p {
    font-family: Spartan;
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    text-align: left;
    color: #151D79;
}

/* Media Query para pantallas menores a 778px */
@media (max-width: 778px) {
    .package-row {
        flex-direction: column;
    }

    .package-card {
        flex: 1 1 100%;
        max-width: 100%;
        margin-bottom: 20px;
    }
}
