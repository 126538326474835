/* Ademas.css */
.ademas {
    max-width: 1140px;
    margin: 0 auto;
    text-align: center;
    padding: 40px 20px;
}

.ademas h2 {
    font-family: 'Spartan', sans-serif;
    font-size: 22px;
    font-weight: 700;
    line-height: 40px;
    color: #151D79;
    text-decoration: underline;
}

.ademas p {
    font-family: 'Inter', sans-serif;
    font-size: 17px;
    font-weight: 400;
    line-height: 27px;
    color: #4A4E56;
}
