.asistencia {
    max-width: 1140px;
    margin: 0 auto;
    padding: 40px 20px;
}

.asistencia-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.asistencia-left img {
    max-width: 550px;
    min-width: 300px;
    width: 100%;
    border-radius: 10px;
}

.asistencia-right {
    max-width: 535px;
    padding-left: 20px;
}

.asistencia-right h2 {
    font-family: 'Spartan', sans-serif;
    font-size: 21px;
    font-weight: 700;
    line-height: 50px;
    text-align: left;
    color: #333;
}

.asistencia-right p {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #4A4E56;
}

.asistencia-right p strong {
    font-weight: 700;
}

.btn-emergencia {
    padding: 15px 20px;
    border: none;
    cursor: pointer;
    border-radius: 10px 0 10px 0;
    background-color: #151D79;
    color: white;
    font-family: 'Spartan', sans-serif;
    font-size: 17px;
    font-weight: 600;
    margin-top: 20px;
    display: block;
    margin: 0 auto;
}

/* Media Query para pantallas menores a 675px */
@media (max-width: 675px) {
    .asistencia-content {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .asistencia-right {
        padding-left: 0;
    }

    .asistencia-left {
        margin-top: 20px;
    }

    .asistencia-right h2,
    .asistencia-right p {
        text-align: center;
    }
}
