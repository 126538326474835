.requisitos-autorizacion {
    text-align: center;
    padding: 40px 20px;
    background-color: #FFFFFF;
}

.requisitos-autorizacion a {
    font-family: 'Spartan', sans-serif;
    font-size: 22px;
    font-weight: 700;
    line-height: 40px;
    color: #151D79;
    margin-bottom: 20px;
    display: block;
}

.requisitos-autorizacion p {
    font-family: 'Inter', sans-serif;
    font-size: 17px;
    font-weight: 400;
    line-height: 27px;
    color: #4A4E56;
    margin-bottom: 40px;
}

.requisitos-cards {
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
}

.requisito-card {
    background-color: white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    min-height: 134px;
    align-items: center;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 335px;
    text-align: left;
    margin-bottom: 20px; /* Added margin for spacing when stacked */
}

.requisito-card img {
    width: 60px;
    height: 60px;
    margin: 0 20px;
}

.requisito-card p {
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-weight: 400;
    margin-top: 40px;
    color: #4A4E56;
}

/* Media Query para pantallas menores a 800px */
@media (max-width: 800px) {
    .requisitos-cards {
        flex-direction: column;
        align-items: center;
    }
}
