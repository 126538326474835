.navbar {
    background-color: white;
    padding: 10px;
    position: sticky; 
    top: 0;
    z-index: 1000; 
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); 
}

.navbar-content {
    max-width: 1140px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.navbar-logo img {
    height: 84px;
    width: 90px;
}

.navbar-links {
    list-style: none;
    display: flex;
    gap: 20px;
    transition: transform 0.3s ease-in-out;
}

.navbar-links a {
    text-decoration: none;
    font-family: Spartan;
    font-size: 15px;
    font-weight: 600;
    line-height: 26px;
    text-align: center;
    color: #282F3B;
}

.navbar-search img {
    height: 24px;
    cursor: pointer;
}

.hamburger-menu {
    display: none;
    cursor: pointer;
}

.hamburger-menu img {
    height: 24px;
    width: 24px;
}

@media (max-width: 800px) {
    .navbar-links {
        position: fixed;
        top: 0;
        right: 0;
        height: 100%;
        background-color: white;
        flex-direction: column;
        justify-content: center;
        gap: 30px;
        transform: translateX(100%);
        z-index: 10;
    }

    .navbar-links.open {
        transform: translateX(0);
    }

    .hamburger-menu {
        display: block;
    }

    .navbar-search {
        display: none;
    }
}
