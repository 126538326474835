/* VisaAdvise.css */
.visa-advise {
    background-color: #151D79;
    color: white;
    text-align: center;
    padding: 10px;
}

.visa-advise-content {
    max-width: 1140px;
    margin: 0 auto;
    display: flex;
    justify-content:space-between;
    align-items: center;
}

.visa-advise-content button {
    background: none;
    color: white;
    padding: 5px 20px;
    border: 1px solid white;
    border-radius: 30px;
    cursor: pointer;
}
