/* Footer.css */
.footer {
    background-color: #282F3B;
    color: #e0e0e0;
    padding-top: 40px;
    font-family: 'Inter', sans-serif;
}

.footer-content {
    max-width: 1140px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.footer-section {
    flex: 1 1 450px;
    margin: 0 20px;
}
.footer-section2 {
    flex: 1 1 200px;
    margin: 0 20px;
}

.footer-logo {
    width: 50px;
}

.footer-section h3 {
    font-family: 'Spartan', sans-serif, sans-serif;
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
    color: #ffffff;
    margin-bottom: 40px;
}

.footer-section p, .footer-section ul, .footer-section li {
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    color: #AAAFB8;
}

.footer-section ul {
    list-style: none;
    padding: 0;
}

.footer-section li {
    margin-bottom: 10px;
}

.footer-bottom {

    padding: 20px 0;
    margin-top: 40px;
    border-top: 1px solid #444;
    background-color: #1F2530;
}

.footer-bottom-content {
    max-width: 1140px;
    padding: 0 10px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.footer-bottom p {
    margin: 0;
}

.footer-bottom a {
    color: #e0e0e0;
    text-decoration: none;
}

.footer-bottom a:hover {
    text-decoration: underline;
}

.footer-section2 h3 {
    font-family: 'Spartan', sans-serif, sans-serif;
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
    color: #ffffff;
    margin-bottom: 40px;
}

.footer-section2 p, .footer-section2ul, .footer-section2 li {
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    color: #AAAFB8;
}

.footer-section2 ul {
    list-style: none;
    padding: 0;
}

.footer-section2 li {
    margin-bottom: 10px;
}
