/* FAQ.css */
.faq {
    position: relative;
    background-image: url('../../public/background_menu2.png'); 
    background-size: cover;
    background-position: center;
    color: #ffffff;
    padding: 40px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 485px;
}

.faq-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(40, 47, 59, 0.85);
    z-index: 1;
}

.faq-content {
    position: relative;
    max-width: 1140px;
    text-align: center;
    z-index: 2;
}

.faq h1 {
    font-family: 'Spartan', sans-serif;
    font-size: 48px;
    font-weight: 700;
    line-height: 60px;
    text-align: center;
    color: #ffffff;
}
