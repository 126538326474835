.faq-questions {
    max-width: 1140px;
    margin: 0 auto;
    padding: 40px 20px;
    text-align: center;
}

.faq-questions h2 {
    font-family: 'Spartan', sans-serif;
    font-size: 22px;
    font-weight: 700;
    line-height: 40px;
    color: #151D79;
    text-decoration: underline;
}

.faq-questions h3 {
    font-family: 'Spartan', sans-serif;
    font-size: 30px;
    font-weight: 700;
    line-height: 40px;
    margin-bottom: 20px;
    margin-top: 0;
    color: #000000;
}

.faq-questions-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
}

.faq-question-item {
    width: 100%;
}

.faq-question {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: start;
    padding: 15px 20px;
    background: #F0F3F9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 26px;
    color: #4A4E56;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.faq-question.selected {
    background: #FFFFFF;
}

.faq-question-button {
    background: #FFFFFF;
    border: none;
    border-radius: 5px;
    height: 40px;
    width: 40px ;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.faq-question-button.open {
    background: #151D79;
}

.faq-question-button .icon {
    fill: currentColor;
}

.faq-question-button .icon.sele {
    color: white !important;
}

.faq-answer {
    padding: 15px 20px;
    background: #F0F3F9;
    border-radius: 0 0 8px 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    line-height: 24px;
    color: #4A4E56;
    text-align: left;
    margin-top: -8px;
    animation: fadeIn 0.3s ease;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@media (max-width: 768px) {
    .faq-questions-list {
        grid-template-columns: 1fr;
    }
}
