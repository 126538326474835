/* AutorizacionFamilias.css */
.autorizacion-familias {
    background-image: 
        linear-gradient(to left, rgba(255, 255, 255, 0.22), #f0f3f9),
        url('../../public/background-image_family.png');
        background-size: cover;
        background-position: center;
        padding: 80px 20px;
        color: #333;

    }
    
    .content {
        max-width: 1140px;
        display: flex;
        flex-direction: column;
    }
    .clas_con{
        align-items: start !important;
    }
    .Content_auto{
        max-width: 500px;
    }
    
    .content h2 {
        font-family: 'Spartan', sans-serif;
        font-size: 22px;
        font-weight: 700;
        line-height: 40px;
        letter-spacing: 1.3px;
        text-align: left;
        color: #151D79;
        margin: 0;
    }
    
    .content h1 {
        font-family: 'Spartan', sans-serif;
        font-size: 51px;
        font-weight: 800;
        line-height: 66px;
        text-align: left;
        color: #333;
        margin: 0;
    }
    
    .content ul {
        list-style: none;
        padding: 0;
        font-family: 'Inter', sans-serif;
        font-size: 17px;
        font-weight: 400;
        line-height: 27px;
        text-align: left;
        color: #333;
    }
    
    .content ul li {
        margin: 5px 0;
    }
    
    .buttons2 {
        margin-top: 20px;
        margin: 0 30px;
    }
    
    .solicitar-button11, .renovar-button11 {
        padding: 15px 30px;
        border: none;
        cursor: pointer;
        margin-right: 10px;
        border-radius: 5px;
        font-family: 'Spartan', sans-serif;
        font-size: 17px;
        font-weight: 600;
        border-radius: 10px 0 10px 0;
        margin-left: 50px;
    }
    
    .solicitar-button11 {
        background-color: #151D79;
        color: white;
    }
    
    .renovar-button11 {
        background-color: black;
        color: white;
    }
    @media (max-width: 700px) {
        .solicitar-button11, .renovar-button11 {
            margin-left: 0;
        }
        .content h1 {
            font-size: 40px
        }
    }