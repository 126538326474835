.cita-embajada {
    background-color: #FFFFFF;
    padding: 40px 20px;
    color: #333;
    text-align: center;
}

.conoce-mas {
    display: block;
    font-family: 'Spartan', sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
    color: #151D79;
    text-decoration: none;
    margin-bottom: 10px;
}

.cita-header h1 {
    font-family: 'Spartan', sans-serif;
    font-size: 38px;
    font-weight: 700;
    line-height: 50px;
    color: #333;
    margin-top: 0;
}

.cita-content {
    max-width: 1140px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: left;
}

.cita-image {
    flex: 1;
    margin-right: 40px;
}

.cita-image img {
    width: 100%;
    height: auto;
    border-radius: 8px;
}

.cita-text {
    flex: 1;
    padding-left: 40px;
}

.cita-text p {
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    color: #4A4E56;
    margin-bottom: 20px;
}

.solicitud-button {
    padding: 15px 20px;
    border: none;
    cursor: pointer;
    border-radius: 10px;
    font-family: 'Spartan', sans-serif;
    font-size: 17px;
    font-weight: 600;
    background-color: #151D79;
    color: white;
    display: block;
    margin-top: 20px;
    margin: 20px auto;
    border-radius: 10px 0 10px 0;
}

/* Media Query para pantallas menores a 700px */
@media (max-width: 700px) {
    .cita-content {
        flex-direction: column;
        text-align: center;
    }

    .cita-image {
        margin: 20px 0;
        order: 2;
    }

    .cita-text {
        padding-left: 0;
        order: 1;
    }

    .solicitud-button {
        margin: 20px auto;
    }
}
