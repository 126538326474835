/* ProfesionalidadExperiencia.css */
.profesionalidad-experiencia {
    text-align: center;
    padding: 40px 20px;
    background-color: #f0f3f9;
}

.profesionalidad-experiencia h2 {
    font-family: 'Spartan', sans-serif;
    font-size: 22px;
    font-weight: 700;
    line-height: 40px;
    color: #151D79;
    margin-bottom: 40px;
}

.tarjetas {
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
}

.tarjeta {
    background-color: white;
    padding: 15px 5px;

    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 250px;
    text-align: center;
}

.icono-valor {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin: 20px;
}

.icono-valor img {
    width: 60px;
    height: 80px;
}

.icono-valor span {
    font-family: 'Spartan', sans-serif;
    font-size: 36px;
    font-weight: 700;
    color: #151D79;
    margin-top: 20px;
}
.ima_svg{
    margin-top: 30px;
}
.tarjeta p {
    font-family: Spartan;
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
    text-align: center;
}
.svg_sub{
    margin-top: -25px;
}
.sbg_top{
    margin-top: 15px;
}